import(/* webpackMode: "eager", webpackExports: ["ImageWithFallback"] */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/apps/core/ui/image-with-fallback.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Cinema"] */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/apps/desktop/src/ui/home/cinema.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Expected"] */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/apps/desktop/src/ui/home/expected.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AsideBanners"] */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/apps/desktop/src/ui/shared/aside-banners.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HorizontalBanner"] */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/apps/desktop/src/ui/shared/horizontal-banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewsAndReviews"] */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/apps/desktop/src/ui/shared/news-and-reviews.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/node_modules/.pnpm/next-intl@3.25.3_next@15.0.3_@playwright+test@1.48.2_react-dom@19.0.0-rc-02c0e824-20241028_re_3e5bkd3rc4kobsprzjvs6nskdu/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/node_modules/.pnpm/next-intl@3.25.3_next@15.0.3_@playwright+test@1.48.2_react-dom@19.0.0-rc-02c0e824-20241028_re_3e5bkd3rc4kobsprzjvs6nskdu/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/node_modules/.pnpm/next-intl@3.25.3_next@15.0.3_@playwright+test@1.48.2_react-dom@19.0.0-rc-02c0e824-20241028_re_3e5bkd3rc4kobsprzjvs6nskdu/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/packages/api/src/materials/hooks.ts");
;
import(/* webpackMode: "eager" */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/packages/api/src/use-collection.ts");
